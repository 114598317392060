import { necsActions } from './necs-actions';
import baseDataReducer from '../baseDataReducer';

const { iniState, reducer } = baseDataReducer('necs');

const initialState = {
  ...iniState,
  account: null,
  goAccounts: [],
  offset: 0,
  perPage: 25,
  goCertificatesOnExchange: 0,
  totalGoCertificates: 0,
  necsAccountConnectionResult: null,
  pdNames: [],
};

const necsReducer = (state = initialState, action) => {
  switch (action.type) {
    case necsActions.setNecsAccount: {
      return { ...state, account: action.payload };
    }
    case necsActions.setGoAccounts: {
      return { ...state, goAccounts: action.payload };
    }
    case necsActions.setGoCertificatesOnExchange: {
      return { ...state, goCertificatesOnExchange: action.payload };
    }
    case necsActions.setTotalGoCertificates: {
      return { ...state, totalGoCertificates: action.payload };
    }
    case necsActions.setNecsAccountConnectionResult: {
      return { ...state, necsAccountConnectionResult: action.payload };
    }
    case necsActions.setGoCertificatesPdNames: {
      return { ...state, pdNames: action.payload };
    }
    default:
      return reducer(state, action);
  }
};

export default necsReducer;
