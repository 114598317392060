import * as axios from 'axios';
import { onError } from '../redux/error-reducer';

const API_URL = process.env.REACT_APP_API_URL || 'https://api.flow.reveel.no';

/*
 * withErrorCheck() API Middleware - all request should pass it
 * to catch and handle errors also this errors stores to error-reducer
 *
 */
const withErrorCheck = async ({
  method = 'get',
  uri: incoming_uri = '',
  data,
  user_token = null,
  additional_headers,
  removeNullParam = false,
  params,
  showErrorToast = true, // Control error toast
}) => {
  return new Promise(async (resolve, reject) => {
    let token = window.localStorage.getItem('user_token') || user_token;
    let uri = incoming_uri;

    if (params) {
      let uri_params = new URLSearchParams();
      for (const key in params) {
        if ((params[key] === null || params[key] === undefined) && removeNullParam) continue;
        uri_params.set(key, params[key]);
      }
      uri = `${uri}?${uri_params.toString()}`;
    }

    const instance = axios.create({
      baseURL: API_URL,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...additional_headers,
      },
      withCredentials: false,
    });

    try {
      let resp;
      if (method === 'get') {
        resp = await instance.get(uri);
      } else if (method === 'post') {
        const formData = new FormData();
        for (const key in data) {
          if (key.includes('[]')) {
            for (const [i, val] of data[key].entries()) {
              formData.append(key, val);
            }
            continue;
          }
          formData.append(key, data[key]);
        }
        resp = await instance.post(uri, formData);
      } else if (method === 'delete') {
        resp = await instance.delete(uri, { withCredentials: true });
      }
      if ((resp.status || resp.data.status) !== 200) {
        if (showErrorToast) {
          onError({ ...resp.data }, true);
        }
        return reject(resp);
      }
      resolve(resp.data.data ? resp.data.data : resp.data);
    } catch (error) {
      const responseData = error.response?.data;
      const validationData = responseData?.data?.validation;

      if (!showErrorToast) {
        return reject(responseData || error);
      }

      if (validationData && Object.prototype.toString.call(validationData) === '[object Object]') {
        for (const [_, value] of Object.entries(validationData)) {
          onError(
            {
              status: (responseData || error).status || 9999,
              message: value || 'Internal error',
            },
            true,
          );
        }
        reject(error);
        return;
      }

      let message = '';
      if (validationData) {
        message = validationData;
      } else if (typeof responseData?.data?.error === 'string') {
        message = responseData?.data?.error;
      } else if (typeof responseData?.data === 'string') {
        message = responseData?.data;
      } else if (typeof responseData?.message === 'string') {
        message = responseData?.message;
      } else if (typeof error?.message === 'string') {
        message = error?.message;
      }

      onError(
        {
          status: (responseData || error).status || 9999,
          message: message || 'Internal error',
        },
        true,
      );
      reject(error);
    }
  });
};

const fileDownload = async (uri) => {
  let token = window.localStorage.getItem('user_token');
  try {
    const file = await axios({
      url: `${process.env.REACT_APP_API_URL}${uri}`, //your url
      method: 'GET',
      responseType: 'blob', // important
      withCredentials: true,
      timeout: 60 * 20 * 1000,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return file.data;
  } catch (error) {
    return false;
  }
};

const API = {
  request: async ({ uri, method, data = {} }) => await withErrorCheck({ method, uri, data }),

  invitation: {
    add: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: '/invitation/add',
        data,
      }),
    send: async (code) =>
      await withErrorCheck({
        method: 'get',
        uri: `/invitation/send?code=${code}`,
      }),
    find: async (params) =>
      await withErrorCheck({
        method: 'post',
        params,
        uri: `/invitation/find`,
      }),
    remove: async ({ id }) =>
      await withErrorCheck({
        method: 'post',
        uri: `/invitation/delete?id=${id}`,
      }),
  },

  language: {
    get: async () =>
      await withErrorCheck({
        method: 'get',
        uri: '/getLanguages',
      }),
  },

  user: {
    register: async (data) => await withErrorCheck({ method: 'post', uri: '/auth/signup', data }),
    auth: async (data) => await withErrorCheck({ method: 'post', uri: '/auth/login', data }),
    logout: async () => await withErrorCheck({ method: 'post', uri: '/auth/logout' }),
    getMe: async () => await withErrorCheck({ method: 'get', uri: '/auth/getMe' }),

    refreshToken: async (token) => await withErrorCheck({ method: 'get', uri: `/auth/refresh/${token}` }),
    getOneById: async ({ id }) => await withErrorCheck({ method: 'get', uri: `/user/get?id=${id}` }),
    remove: async ({ id }) =>
      await withErrorCheck({
        method: 'post',
        uri: `/user/delete?id=${id}`,
      }),
    edit: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: `/user/save/${data.id}`,
        data,
      }),
    editProfile: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: `/user/save-profile/${data.id}`,
        data,
      }),
    get: async (params) =>
      await withErrorCheck({
        removeNullParam: true,
        method: 'post',
        params,
        uri: `/user/find`,
      }),
    findFlowAndMarket: async (params) =>
      await withErrorCheck({
        method: 'get',
        params,
        uri: `/user/findFlowAndMarket`,
      }),
    add: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: '/user/save',
        data: {
          ...data,
          roles: JSON.stringify(data.roles),
        },
      }),
    getAvailableRoles: () =>
      withErrorCheck({
        method: 'get',
        uri: '/role/find',
      }),
    /**
     * add roles if user doesn't have them, remove otherwise
     * @param {number} userId
     * @param {Array<{id: number}>} roles
     * @returns {Promise}
     */
    updateRoles: (userId, roles) =>
      withErrorCheck({
        method: 'post',
        uri: '/user/role/update',
        data: { user: userId, roles: JSON.stringify(roles) },
      }),
    bindCompanies: (userId, companiesIds) =>
      withErrorCheck({
        method: 'post',
        uri: '/user/role/company_list',
        data: { user: userId, companies: JSON.stringify(companiesIds) },
      }),
    resetPassword: async (email) => {
      return withErrorCheck({ method: 'get', uri: `/user/reset-password/${email}` });
    },

    resetPasswordConfirmation: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: `/user/reset-password-confirmation`,
        data,
      }),
  },
  companies: {
    getByNamePattern: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: '/auth/getCompanyByNamePattern',
        data,
      }),
    sendStripeAccountEmail: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: '/company/sendStripeAccountEmail',
        data,
      }),
    createStripeAccount: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: '/company/createStripeAccount',
        data,
      }),
    createOauthStripeConnectLink: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: '/company/createOauthStripeConnectLink',
        data,
      }),
    toggleStripePayment: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: '/company/toggleStripePayment',
        data,
      }),
    getOneById: async ({ id }) =>
      await withErrorCheck({
        method: 'get',
        uri: `/company/get?id=${id}`,
      }),
    remove: async ({ id }) =>
      await withErrorCheck({
        method: 'post',
        uri: `/company/delete?id=${id}`,
      }),
    edit: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: `/company/save/${data.id}`,
        data,
      }),
    get: async (params) =>
      await withErrorCheck({
        removeNullParam: true,
        method: 'get',
        params,
        uri: `/company/find`,
      }),
    add: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: '/company/save',
        data,
      }),
    getTypes: (params) =>
      withErrorCheck({
        method: 'get',
        uri: '/company/type/find',
        params,
      }),
    saveType: (data) =>
      withErrorCheck({
        method: 'post',
        uri: '/company/type/save',
        data,
      }),
    removeType: (id) =>
      withErrorCheck({
        method: 'post',
        uri: '/company/type/delete',
        params: { id },
      }),
    addRelation: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: '/company/add_relation',
        data,
      }),
    removeRelation: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: '/company/remove_relation',
        data: data,
      }),
    acceptRelation: async ({ relation, company }) =>
      await withErrorCheck({
        method: 'get',
        uri: `/company/accept_relation?relation=${relation}&company=${company}`,
      }),
    declineRelation: async ({ relation, company }) =>
      await withErrorCheck({
        method: 'get',
        uri: `/company/decline_relation?relation=${relation}&company=${company}`,
      }),
  },
  products: {
    getOneById: async ({ id }) =>
      await withErrorCheck({
        method: 'get',
        uri: `/product/get?id=${id}`,
      }),
    remove: async ({ id }) =>
      await withErrorCheck({
        method: 'post',
        uri: `/product/delete?id=${id}`,
      }),
    edit: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: `/product/save/${data.id}`,
        data,
      }),
    get: async (params) =>
      await withErrorCheck({
        removeNullParam: true,
        method: 'post',
        params,
        uri: `/product/find`,
      }),
    getByCompanyAndID: async ({ name, company_id }) =>
      await withErrorCheck({
        method: 'post',
        uri: `/product/find?name=${name}&company_id=${company_id}`,
      }),
    add: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: '/product/save',
        data,
      }),
    getAllergens: async (params) =>
      await withErrorCheck({
        removeNullParam: true,
        method: 'get',
        params,
        uri: `/product/findAllergens`,
      }),
    getAttributes: async (params) =>
      await withErrorCheck({
        removeNullParam: true,
        method: 'get',
        params,
        uri: `/product/findAttributes`,
      }),
    getTags: async (params) =>
      await withErrorCheck({
        removeNullParam: true,
        method: 'get',
        params,
        uri: `/product/findTags`,
      }),
  },
  productWrappers: {
    add: async (data) =>
      await withErrorCheck({
        removeNullParam: true,
        method: 'post',
        uri: '/product/wrapper/save',
        data,
      }),
    setProduct: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: '/product/wrapper/setProduct',
        data,
      }),
    removeProduct: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: '/product/wrapper/removeProduct',
        data,
      }),
    edit: async (data) =>
      await withErrorCheck({
        removeNullParam: true,
        method: 'post',
        uri: `/product/wrapper/save/${data.id}`,
        data,
      }),
    find: async (params) =>
      await withErrorCheck({
        removeNullParam: true,
        method: 'get',
        params,
        uri: `/product/wrapper/find`,
      }),
    getByCompany: async ({ id, orderId, variantId, productId }) => {
      const filterParams = (params) => {
        return Object.fromEntries(Object.entries(params).filter(([_, value]) => value));
      };
      const filteredParams = filterParams({ id, orderId, variantId, productId });
      return await withErrorCheck({
        method: 'get',
        uri: `/product/wrapper/getByCompany`,
        params: filteredParams,
      });
    },
    remove: async ({ id }) =>
      await withErrorCheck({
        method: 'get',
        uri: `/product/wrapper/delete?id=${id}`,
      }),
    getTypes: (params) =>
      withErrorCheck({
        method: 'get',
        uri: 'product/wrapperType/find',
        params,
      }),
  },
  productWrapperTypes: {
    add: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: '/product/wrapperType/save',
        data,
      }),
    find: async (params) =>
      await withErrorCheck({
        removeNullParam: true,
        method: 'get',
        params,
        uri: 'product/wrapperType/find',
      }),
    remove: async ({ id }) =>
      await withErrorCheck({
        method: 'get',
        uri: `/product/wrapperType/delete?id=${id}`,
      }),
  },
  specialFunctions: {
    getWrappers: async () =>
      await withErrorCheck({
        method: 'get',
        uri: '/specialFunctions/getWrappers',
      }),
    getShops: async () =>
      await withErrorCheck({
        method: 'get',
        uri: '/specialFunctions/getShops',
      }),
    generatePdf: async (paramsString) => await fileDownload(`/specialFunctions/generatePdf?${paramsString}`),
  },
  platformSettings: {
    getLocaleNamespaces: async () =>
      await withErrorCheck({
        method: 'get',
        uri: '/platformSettings/getLocaleNamespaces',
      }),
    getLocalesByNameSpace: async (params) =>
      await withErrorCheck({
        method: 'get',
        uri: '/platformSettings/getLocalesByNameSpace',
        params,
      }),
    saveLocale: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: '/platformSettings/saveLocale',
        data,
      }),
    getPublic: async () =>
      await withErrorCheck({
        method: 'get',
        uri: '/platformSettings/getPublic',
      }),
    getBranding: async () =>
      await withErrorCheck({
        method: 'get',
        uri: '/platformSettings/getBranding',
      }),
    restoreBranding: async () =>
      await withErrorCheck({
        method: 'get',
        uri: '/platformSettings/restoreBranding',
      }),
    saveBranding: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: '/platformSettings/saveBranding',
        data,
      }),
    saveDisabledPages: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: '/platformSettings/saveDisabledPages',
        data,
      }),
    getDisabledPages: async () =>
      await withErrorCheck({
        method: 'get',
        uri: '/platformSettings/getDisabledPages',
      }),
    saveUser: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: '/platformSettings/saveUser',
        data,
      }),
    getUser: async () =>
      await withErrorCheck({
        method: 'get',
        uri: '/platformSettings/getUser',
      }),
    exportLocales: async () =>
      await withErrorCheck({
        method: 'get',
        uri: '/platformSettings/exportLocales',
      }),
    importLocales: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: '/platformSettings/importLocales',
        data,
      }),
  },
  productTickets: {
    get: async (params) =>
      await withErrorCheck({
        method: 'get',
        uri: '/product/tickets/get',
        params,
      }),
    validate: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: '/product/tickets/validate',
        data,
      }),
  },
  review: {
    findProductReviews: async (params) =>
      await withErrorCheck({
        removeNullParam: true,
        method: 'get',
        params,
        uri: `/product/review/find`,
      }),
    updateProductReviewVisibility: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: `/product/review/toggleVisibility/${data.id}`,
        data,
      }),
    findCompanyReviews: async (params) =>
      await withErrorCheck({
        removeNullParam: true,
        method: 'get',
        params,
        uri: `/company/review/find`,
      }),
    updateCompanyReviewVisibility: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: `/company/review/toggleVisibility/${data.id}`,
        data,
      }),
  },
  orders: {
    addMeasurements: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: `measurement/add`,
        data,
      }),
    search: async ({ rdid = null, image = null }) => {
      const data = {};
      if (rdid) data.rdid = rdid;
      if (image) data.image = image;
      const res = await withErrorCheck({
        method: 'post',
        uri: `/search`,
        data,
        additional_headers: image ? { 'Content-Type': 'multipart/form-data' } : {},
      });
      return res;
    },
    getOneById: async ({ id }) => await withErrorCheck({ method: 'get', uri: `/order/get?id=${id}` }),
    remove: async ({ id }) =>
      await withErrorCheck({
        method: 'post',
        uri: `/order/delete?id=${id}`,
      }),
    edit: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: `/order/save/${data.id}`,
        data,
      }),
    get: async (params) =>
      await withErrorCheck({
        method: 'post',
        params,
        removeNullParam: true,
        uri: `/order/find`,
      }),
    add: async (data) => await withErrorCheck({ method: 'post', uri: '/order/save', data }),
    getLanding: async ({ id }) =>
      await withErrorCheck({
        method: 'get',
        uri: `order/landing/get?id=${id}`,
      }),
    createUnits: async ({ product, order, count }) =>
      await withErrorCheck({
        method: 'get',
        uri: `/order/units/create?count=${count}&order=${order}&product=${product}`,
      }),
    resetUnits: async ({ product, order }) =>
      await withErrorCheck({
        method: 'get',
        uri: `/order/units/reset?order=${order}&product=${product}`,
      }),
    findUnits: async ({ product, order, limit, offset }) =>
      await withErrorCheck({
        method: 'get',
        uri: `/order/units/find?limit=${limit}&offset=${offset}&order=${order}&product=${product}`,
      }),
    getOneUnit: async (id) => await withErrorCheck({ method: 'get', uri: `unit/get?id=${id}` }),
    getPdf: async ({ product, order }) => await fileDownload(`/order/units/getPdf?order=${order}&product=${product}`),
    getVariantPdfs: async ({ offset, order }) =>
      await fileDownload(`/order/varinats/getPdf?order=${order}&offset=${offset}`),
    getLogs: async ({ entity, id }) =>
      await withErrorCheck({
        method: 'get',
        uri: `/log?entity=${entity}&id=${id}`,
      }),
    handOver: async (id, data) =>
      await withErrorCheck({
        method: 'post',
        uri: `/order/handover/${id}`,
        data: data,
      }),
    handOverCreate: async () =>
      await withErrorCheck({
        method: 'post',
        uri: `/order/handoverCreate`,
      }),
    handOverDelete: async ({ id }) =>
      await withErrorCheck({
        method: 'get',
        uri: `/order/handoverDelete?id=${id}`,
      }),
    clone: async ({ id }) =>
      await withErrorCheck({
        method: 'get',
        uri: `/order/clone/${id}`,
      }),
    getTypes: () =>
      withErrorCheck({
        method: 'get',
        uri: `order/types`,
      }),
    toggleLanding: (data) =>
      withErrorCheck({
        method: 'post',
        uri: 'order/toggleLanding',
        data,
      }),
    toggleLandingPrivate: (data) =>
      withErrorCheck({
        method: 'post',
        uri: 'order/toggleLandingPrivate',
        data,
      }),
    getCertificates: (id) =>
      withErrorCheck({
        method: 'get',
        uri: `order/${id}/getVariants`,
      }),
    saveCertificates: (id, data) =>
      withErrorCheck({
        method: 'post',
        uri: `order/${id}/saveVariants`,
        data: {
          variants: data,
        },
      }),
  },
  landing: {
    saveLanding: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: `order/landing/pods/save`,
        data,
      }),
    getSelected: async ({ id }) =>
      await withErrorCheck({
        method: 'get',
        uri: `order/landing/get?id=${id}`,
      }),
    getPodTypeBySlug: async ({ slug }) =>
      await withErrorCheck({
        method: 'get',
        uri: `order/landing/pod_types/find?slug=${slug}`,
      }),
    getPublic: async ({ id, unit }) =>
      await withErrorCheck({
        method: 'get',
        uri: `order/landing/get?id=${id}${unit ? '&unit=' + unit : ''}`,
      }),
    savePod: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: `order/landing/pod/save/${data.id}`,
        data,
      }),
    addPod: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: `order/landing/pod/save`,
        data,
      }),
    removePod: async ({ id }) =>
      await withErrorCheck({
        method: 'get',
        uri: `order/landing/pod/delete?id=${id}`,
      }),
    getLanguages: async () =>
      await withErrorCheck({
        method: 'get',
        uri: 'order/landing/getLanguages',
      }),
    setLanguage: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: 'order/landing/setLanguage',
        data,
      }),
  },
  productTypes: {
    get: async ({ limit, offset }) =>
      await withErrorCheck({
        method: 'post',
        uri: `/product/type/find?limit=${limit}&offset=${offset}`,
      }),
  },
  documents: {
    get: async ({
      limit,
      offset,
      excludeId = '',
      search = '',
      type = '',
      entity = '',
      company = '',
      active = '',
      startDate = '',
      endDate = '',
      orderCreatedAtDate = '',
      orderEntity = '',
      orderCompany = '',
      orderTypes = '',
    }) =>
      await withErrorCheck({
        method: 'post',
        uri: `/document/find?limit=${limit}&offset=${offset}&excludeId=${excludeId}&search=${search}&type=${type}`,
        data: {
          entity: entity,
          company: company,
          active: active,
          startDate: startDate,
          endDate: endDate,
          orderCreatedAtDate: orderCreatedAtDate,
          orderEntity: orderEntity,
          orderCompany: orderCompany,
          orderTypes: orderTypes,
        },
      }),
    getTypes: async ({ limit = 20, offset = 0, entity }) =>
      await withErrorCheck({
        method: 'post',
        uri: `/document/type/find?limit=${limit}&offset=${offset}&entity=${entity}`,
      }),
    add: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: '/document/save',
        data,
      }),
    edit: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: `/document/save/${data.id}`,
        data,
      }),
    remove: async ({ id }) =>
      await withErrorCheck({
        method: 'post',
        uri: `/document/delete?id=${id}`,
      }),
  },
  assets: {
    download: fileDownload,
    getCunkInfo: async ({ source_uri }) =>
      await withErrorCheck({
        method: 'get',
        uri: `/assets/chunk_info?uri=${encodeURIComponent(source_uri)}`,
      }),
    uploadImage: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: `/assets/upload_image`,
        data,
      }),
    convertAudio: async (id) =>
      await withErrorCheck({
        method: 'get',
        uri: `/assets/convert_audio?id=${id}`,
      }),
    delete: async (id) =>
      await withErrorCheck({
        method: 'get',
        uri: `/assets/delete?id=${id}`,
      }),
    get: (params) =>
      withErrorCheck({
        method: 'get',
        uri: `/assets/find`,
        params,
      }),
    downloadImage: (id) =>
      withErrorCheck({
        method: 'get',
        uri: `/assets/download`,
        params: { id },
      }),
    getImageBase64: (id) =>
      withErrorCheck({
        method: 'get',
        uri: `/assets/get_image_base64`,
        params: { id },
      }),
  },
  vault: {
    getTypes: () =>
      withErrorCheck({
        method: 'get',
        uri: `/vault/getTypes`,
      }),
    get: (params = { offset: 0 }) =>
      withErrorCheck({
        method: 'post',
        uri: `/vault/find`,
        params,
      }),
    save: (data) =>
      withErrorCheck({
        method: 'post',
        uri: `/vault/save`,
        data,
      }),
    delete: (id) =>
      withErrorCheck({
        method: 'get',
        uri: `/vault/delete?id=${id}`,
      }),
    addToLanding: (pod_id, landing_id) =>
      withErrorCheck({
        method: 'post',
        uri: `/vault/addOnLanding`,
        data: {
          pod: pod_id,
          landing: landing_id,
        },
      }),
    removeFromLanding: (pod_id, landing_id) =>
      withErrorCheck({
        method: 'post',
        uri: `/vault/removeFromLanding`,
        data: {
          pod: pod_id,
          landing: landing_id,
        },
      }),
    getPod: (id) =>
      withErrorCheck({
        method: 'post',
        uri: `/vault/getPod`,
        params: { id },
      }),
  },
  marketplaceHelp: {
    getCategory: async (params) =>
      await withErrorCheck({
        method: 'get',
        params,
        removeNullParam: true,
        uri: '/marketplace/help/get_category',
      }),
    getCategories: async (params) =>
      await withErrorCheck({
        method: 'get',
        params,
        removeNullParam: true,
        uri: '/marketplace/help/find_categories',
      }),
    addCategory: async (data) =>
      await withErrorCheck({
        method: 'post',
        data,
        removeNullParam: true,
        uri: '/marketplace/help/save_category',
      }),
    editCategory: async (data) =>
      await withErrorCheck({
        method: 'post',
        data,
        removeNullParam: true,
        uri: '/marketplace/help/save_category/' + data.id,
      }),
    saveCategoryOrder: async (data) =>
      await withErrorCheck({
        method: 'post',
        data,
        removeNullParam: true,
        uri: '/marketplace/help/update_category_order',
      }),
    removeCategory: async (hash) =>
      await withErrorCheck({
        method: 'get',
        removeNullParam: true,
        uri: '/marketplace/help/delete_category?hash=' + hash,
      }),
    getQuestionsByCategory: async (params) =>
      await withErrorCheck({
        method: 'get',
        params,
        removeNullParam: true,
        uri: '/marketplace/help/get_questions_by_category',
      }),
    addQuestion: async (data) =>
      await withErrorCheck({
        method: 'post',
        data,
        removeNullParam: true,
        uri: '/marketplace/help/save_question',
      }),
    editQuestion: async (data) =>
      await withErrorCheck({
        method: 'post',
        data,
        removeNullParam: true,
        uri: '/marketplace/help/save_question/' + data.id,
      }),
    removeQuestion: async (hash) =>
      await withErrorCheck({
        method: 'get',
        removeNullParam: true,
        uri: '/marketplace/help/delete_questions?hash=' + hash,
      }),
  },
  giftCards: {
    getCards: async (params) =>
      await withErrorCheck({
        method: 'get',
        params,
        removeNullParam: true,
        uri: '/giftcards/find',
      }),
  },
  marketplace: {
    getCategory: async (id) =>
      await withErrorCheck({
        method: 'get',
        uri: '/marketplace/categories/' + id,
      }),
    getCategories: async (params) =>
      await withErrorCheck({
        method: 'post',
        params,
        removeNullParam: true,
        uri: '/marketplace/categories',
      }),
    getCategoriesByNamePattern: async (name) =>
      await withErrorCheck({
        method: 'get',
        removeNullParam: true,
        uri: '/marketplace/find_categories?limit=10&search=' + name,
      }),
    saveCategory: (data, id) => {
      if (id) {
        return withErrorCheck({
          method: 'post',
          uri: '/marketplace/save_category/' + id,
          data,
        });
      }
      return withErrorCheck({
        method: 'post',
        uri: '/marketplace/save_category/',
        data,
      });
    },
    removeCategory: (id) => {
      return withErrorCheck({
        method: 'get',
        uri: '/marketplace/remove_category',
        params: { id },
      });
    },
    saveVariant: (data, id) => {
      let uri = '/marketplace/save_variant/';
      if (id) uri = uri + id;
      return withErrorCheck({
        method: 'post',
        uri,
        data,
      });
    },
    removeVariant: (id) => {
      let uri = '/marketplace/remove_variant/';
      return withErrorCheck({
        method: 'post',
        uri,
        params: { id },
      });
    },
    saveInventory: (data, id) => {
      let uri = '/marketplace/save_inventory/';
      if (id) uri = uri + id;
      return withErrorCheck({
        method: 'post',
        uri,
        data,
      });
    },
    getInventory: async (id) =>
      await withErrorCheck({
        method: 'get',
        uri: '/marketplace/inventory/' + id,
      }),
    findProductInventory: async (id) =>
      await withErrorCheck({
        method: 'get',
        uri: '/marketplace/find_product_inventory/' + id,
      }),
    getInventories: async (params) =>
      await withErrorCheck({
        method: 'post',
        params,
        removeNullParam: true,
        uri: '/marketplace/inventories',
      }),
    getVariantStates: async () =>
      await withErrorCheck({
        method: 'get',
        uri: `/marketplace/get_variant_states`,
      }),
    removeInventory: (id) => {
      return withErrorCheck({
        method: 'get',
        uri: '/marketplace/remove_inventory',
        params: { id },
      });
    },

    // Marketplace -> Icons
    getIcons: async (params) =>
      await withErrorCheck({
        removeNullParam: true,
        method: 'get',
        params,
        uri: `/marketplace/find_icons`,
      }),
    uploadIcons: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: `/marketplace/upload_icons`,
        data,
      }),
    removeIcon: async ({ id }) =>
      await withErrorCheck({
        method: 'post',
        uri: `/marketplace/delete_icon?id=${id}`,
      }),
    editIcon: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: `/marketplace/edit_icon`,
        data,
      }),

    // Marketplace -> Users
    getUsers: async (params) =>
      await withErrorCheck({
        removeNullParam: true,
        method: 'get',
        params,
        uri: `/mp_api_users/find`,
      }),
    removeUser: async ({ id }) =>
      await withErrorCheck({
        method: 'post',
        uri: `/mp_api_users/delete?id=${id}`,
      }),
    editUser: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: `/mp_api_users/save/${data.id}`,
        data,
      }),
    // Marketplace -> Taxes
    getProductTypes: async (params) =>
      await withErrorCheck({
        removeNullParam: true,
        method: 'get',
        params,
        uri: `/mp_api_product_types/find`,
      }),
    editProductType: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: `/mp_api_product_types/save/${data.id}`,
        data,
      }),

    // Marketplace -> Orders [states]
    getOrderStates: async ({ limit, offset }) =>
      await withErrorCheck({
        method: 'post',
        uri: `/marketplace/orders/findStates?limit=${limit}&offset=${offset}`,
      }),
    getOrders: async (params) =>
      await withErrorCheck({
        removeNullParam: true,
        method: 'post',
        params,
        uri: `/marketplace/orders/find`,
      }),
    updateOrderItemQuantity: async (params) =>
      await withErrorCheck({
        removeNullParam: true,
        method: 'post',
        params,
        uri: `/marketplace/orders/updateItemQuantity`,
      }),
    refundOrder: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: `/marketplace/orders/refund`,
        data,
      }),
    cancelOrder: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: `/marketplace/orders/cancel`,
        data,
      }),
    completeOrder: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: `/marketplace/orders/complete`,
        data,
      }),
    downloadOrderPdf: fileDownload,
    updateOrderItemsState: async (data) =>
      await withErrorCheck({
        removeNullParam: true,
        method: 'post',
        data: data,
        uri: `/marketplace/orders/updateItemsState`,
      }),
    removeOrder: async ({ id }) =>
      await withErrorCheck({
        method: 'post',
        uri: `/marketplace/orders/delete?id=${id}`,
      }),
    saveOrder: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: `/marketplace/orders/save/${data.id}`,
        data,
      }),
    getItemsInOrder: async (params) =>
      await withErrorCheck({
        removeNullParam: true,
        method: 'post',
        params,
        uri: `/marketplace/orders/getItems`,
      }),
  },
  necs: {
    saveAccount: async ({ access_code1, access_code2, account_holder_code, email }) =>
      await withErrorCheck({
        removeNullParam: true,
        showErrorToast: false,
        method: 'post',
        uri: `/necs/saveAccount`,
        data: { access_code1, access_code2, account_holder_code, email },
      }),
    getGoAccounts: async ({ offset = 0, limit = 1000 }) =>
      await withErrorCheck({
        removeNullParam: true,
        method: 'get',
        uri: `/necs/getGoAccounts`,
        params: { offset, limit },
      }),
    setGoAccounts: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: `/necs/setGoAccounts`,
        data,
      }),
    findGoCertificates: async ({ offset = 0, limit = 25, ...params }) =>
      await withErrorCheck({
        removeNullParam: true,
        method: 'get',
        uri: `/goCertificate/find`,
        params: {
          offset,
          limit,
          ...params,
        },
      }),
    findGoCertificatesPDName: async (search) =>
      await withErrorCheck({
        method: 'get',
        uri: `/goCertificate/findPDName`,
        params: { search },
      }),
    setComment: async (id, comment) =>
      await withErrorCheck({
        method: 'post',
        uri: `/goCertificate/setComment`,
        data: {
          comment,
          go_certificate: id,
        },
      }),
    saveGoExchanges: async (id, exchanges) =>
      await withErrorCheck({
        method: 'post',
        uri: `/goCertificate/saveGoExchanges`,
        data: {
          go_certificate: id,
          go_exchanges: exchanges,
        },
      }),
    disconnectNecsAccount: async () =>
      await withErrorCheck({
        method: 'get',
        uri: `/necs/disconnectAccount`,
      }),
    findNecsTransactions: async (params) =>
      await withErrorCheck({
        method: 'get',
        uri: `/necs/necsTransactions/find`,
        removeNullParam: true,
        params,
      }),
    findNecsProductionDevices: async (params) =>
      await withErrorCheck({
        method: 'get',
        uri: `/necs/necsProductionDevices/find`,
        removeNullParam: true,
        params,
      }),
    getNecsProductionDevice: async (id) =>
      await withErrorCheck({
        method: 'get',
        uri: `/necs/necsProductionDevices/get/${id}`,
        removeNullParam: true,
      }),
    saveNecsProductionDevice: async (data) =>
      await withErrorCheck({
        method: 'post',
        uri: `/necs/necsProductionDevices/save`,
        removeNullParam: true,
        data,
      }),
  },
};

export default API;
