import store from './store';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export interface InitialLoadingState {
  loading: LoadingState;
}
export interface LoadingState {
  [actionName: string]: boolean;
}
