import React, { useState, useEffect } from 'react';
import { useLocation, Switch, useHistory } from 'react-router-dom';
import { useWindowSize } from 'general/hooks';
import AppNav from '@components/AppNav/AppNav';
import NibBreadcrumbs from '@components/NibBreadcrumbs/NibBreadcrumbs';
import { routerLinksSelector } from '@redux/router-reducer';
import { useTypedSelector } from '@redux/store/store.types';

export const Child = ({ children, type }) => children;

const minWidthForPersistedSidebar = 1280;

const Private = ({ children, extProps, publicRoutes, disabledRoutes, nakedRoutes, unprotectedRoutes }) => {
  const [isSideBarOpen, setSideBar] = useState(true);
  const [acceptedComponents, setAccepted] = useState([]);
  const [deniedComponents, setDenied] = useState([]);
  const links = useTypedSelector(routerLinksSelector);

  const history = useHistory();
  const { pathname } = useLocation();
  const { width } = useWindowSize();

  const pathTable = links.reduce((result, link) => {
    result[link.to] = link;
    return result;
  }, {});

  useEffect(() => {
    if (width < minWidthForPersistedSidebar) {
      setSideBar(false);
    }
  }, [pathname, width]);

  useEffect(() => {
    React.Children.forEach(children, (child) => {
      if (child.props.type === 'Accepted') {
        setAccepted(child.props.children);
      } else if (child.props.type === 'Denied') {
        setDenied(child.props.children);
      }
    });
  }, [children]);

  const haveRights = (basePath, roles) => {
    const route = pathTable['/' + basePath];
    if (route?.unprotected === true) return true;
    if (route && route.roles_allowed) {
      for (let role of roles) {
        if (route.roles_allowed.includes(role.slug)) return true;
      }
    }
    return false;
  };

  if (extProps.session.user && !publicRoutes.includes(pathname)) {
    if (nakedRoutes.includes(pathname)) return <Switch>{acceptedComponents}</Switch>;

    const rolesDisabled = !!Number(process.env.REACT_APP_DISABLE_ROLES);
    if (!rolesDisabled) {
      const { hasAdminAccess, roles } = extProps.session.user;
      const firstPathnamePart = pathname.split('/')[1];

      if (
        disabledRoutes.includes(`/${firstPathnamePart}`) ||
        (!unprotectedRoutes.includes(`/${firstPathnamePart}`) &&
          !hasAdminAccess &&
          !haveRights(firstPathnamePart, roles))
      ) {
        history.push(unprotectedRoutes[0]);
      }
    }

    return (
      <>
        <AppNav
          {...extProps.session}
          toggleHelp={extProps.toggleHelp}
          logout={extProps.logout}
          isSideBarOpen={isSideBarOpen}
          setSideBar={setSideBar}
        />
        <main className={isSideBarOpen ? 'withSidebar' : ''}>
          <NibBreadcrumbs />
          <Switch>{acceptedComponents}</Switch>
        </main>
      </>
    );
  } else return <> {deniedComponents}</>;
};

export default Private;
