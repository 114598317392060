import _ from 'lodash';
import { Dispatch } from 'redux';

interface BaseItem {
  id: number | string;
}

type GenericAction<T> = {
  type: string;
  payload?: T;
};

type GenericState<ItemType> = {
  isInited: boolean;
  isLoading: boolean;
  items: ItemType[];
  total: number;
  filters: Record<string, any>;
  sorting: Record<string, any>;
  offset: number;
};

const createActions = (section: string) => ({
  TOGGLE_INIT: `/nibchain/${section}/TOGGLE_INIT`,
  TOGGLE_LOADING: `/nibchain/${section}/TOGGLE_LOADING`,
  SET_ITEMS: `/nibchain/${section}/SET_ITEMS`,
  SET_TOTAL: `/nibchain/${section}/SET_TOTAL`,
  REMOVE_ITEM: `/nibchain/${section}/REMOVE_ITEM`,
  ADD_ITEM: `/nibchain/${section}/ADD_ITEM`,
  EDIT_ITEM: `/nibchain/${section}/EDIT_ITEM`,
  CLEAR_ITEMS_AND_TOTAL: `/nibchain/${section}/CLEAR_ITEMS_AND_TOTAL`,
  CLEAR_ITEMS: `/nibchain/${section}/CLEAR_ITEMS`,
  ADD_FILTER: `/nibchain/${section}/ADD_FILTER`,
  SET_SORT: `/nibchain/${section}/SET_SORT`,
  REMOVE_FILTER: `/nibchain/${section}/REMOVE_FILTER`,
  CLEAR_FILTERS: `/nibchain/${section}/CLEAR_FILTERS`,
  SET_OFFSET: `/nibchain/${section}/SET_OFFSET`,
  CLEAR_SORTS: `/nibchain/${section}/CLEAR_SORTS`,
});

const baseReducer =
  <ItemType extends BaseItem, StateType extends GenericState<ItemType>>(ACTIONS: ReturnType<typeof createActions>) =>
  (state: StateType, action: GenericAction<any>): StateType => {
    switch (action.type) {
      case ACTIONS.TOGGLE_INIT:
        return { ...state, isInited: action.payload };

      case ACTIONS.TOGGLE_LOADING:
        return { ...state, isLoading: action.payload };

      case ACTIONS.SET_ITEMS:
        return {
          ...state,
          items: action.payload.length ? [...state.items, ...action.payload] : state.items,
        };

      case ACTIONS.SET_TOTAL:
        return { ...state, total: action.payload };

      case ACTIONS.REMOVE_ITEM:
        return {
          ...state,
          items: state.items.filter((item) => item.id !== action.payload),
          total: state.total - 1,
        };

      case ACTIONS.ADD_ITEM:
        return {
          ...state,
          items: [...state.items, action.payload],
          total: state.total + 1,
        };

      case ACTIONS.EDIT_ITEM:
        return {
          ...state,
          items: state.items.map((item) => (item.id === action.payload.id ? { ...item, ...action.payload } : item)),
        };

      case ACTIONS.CLEAR_ITEMS:
        return { ...state, items: [] };

      case ACTIONS.CLEAR_ITEMS_AND_TOTAL:
        return {
          ...state,
          items: [],
          total: 0,
          offset: 0,
          filters: {},
          sorting: {},
        };

      case ACTIONS.ADD_FILTER:
        return {
          ...state,
          filters: { ...state.filters, [action.payload.filter]: action.payload.value },
          offset: 0,
          items: [],
          total: 0,
        };

      case ACTIONS.SET_SORT:
        return {
          ...state,
          sorting: { [action.payload.sort]: action.payload.value },
          offset: 0,
          items: [],
          total: 0,
        };

      case ACTIONS.REMOVE_FILTER:
        return {
          ...state,
          filters: _.omit(state.filters, [action.payload]),
          offset: 0,
          items: [],
          total: 0,
        };

      case ACTIONS.CLEAR_FILTERS:
        return {
          ...state,
          filters: {},
          offset: 0,
          items: [],
          total: 0,
        };

      case ACTIONS.CLEAR_SORTS:
        return {
          ...state,
          sorting: {},
          offset: 0,
          items: [],
          total: 0,
        };

      case ACTIONS.SET_OFFSET:
        return { ...state, offset: action.payload };

      default:
        return state;
    }
  };

const generateActionCreators = <ItemType>(ACTIONS: ReturnType<typeof createActions>) => ({
  toggleInit: (status: boolean) => ({ type: ACTIONS.TOGGLE_INIT, payload: status }),
  toggleLoading: (status: boolean) => ({ type: ACTIONS.TOGGLE_LOADING, payload: status }),
  setItems: (items: ItemType[]) => ({ type: ACTIONS.SET_ITEMS, payload: items }),
  setTotal: (total: number) => ({ type: ACTIONS.SET_TOTAL, payload: total }),
  removeItem: (id: number | string) => ({ type: ACTIONS.REMOVE_ITEM, payload: id }),
  addItem: (item: ItemType) => ({ type: ACTIONS.ADD_ITEM, payload: item }),
  editItem: (item: ItemType) => ({ type: ACTIONS.EDIT_ITEM, payload: item }),
  clearItems: () => ({ type: ACTIONS.CLEAR_ITEMS }),
  clearItemsAndTotal: () => ({ type: ACTIONS.CLEAR_ITEMS_AND_TOTAL }),
  addFilter: (filter: string, value: any) => ({
    type: ACTIONS.ADD_FILTER,
    payload: { filter, value },
  }),
  setSort: (sort: string, value: any) => ({ type: ACTIONS.SET_SORT, payload: { sort, value } }),
  removeFilter: (filter: string) => ({ type: ACTIONS.REMOVE_FILTER, payload: filter }),
  clearFilters: () => ({ type: ACTIONS.CLEAR_FILTERS }),
  setOffset: (offset: number) => ({ type: ACTIONS.SET_OFFSET, payload: offset }),
  clearSorts: () => ({ type: ACTIONS.CLEAR_SORTS }),
});

const onSortThunk =
  <ItemType>(AC: ReturnType<typeof generateActionCreators<ItemType>>) =>
  (sort: string, value: any) =>
  (dispatch: Dispatch) => {
    if (value !== null && value !== undefined) {
      dispatch(AC.setSort(sort, value));
    }
  };

const onFilterThunk =
  <ItemType>(AC: ReturnType<typeof generateActionCreators<ItemType>>) =>
  (filter: string, value: any) =>
  (dispatch: Dispatch) => {
    if (filter === 'reset') {
      dispatch(AC.clearFilters());
    } else if (value !== null && value !== undefined) {
      dispatch(AC.addFilter(filter, value));
    } else {
      dispatch(AC.removeFilter(filter));
    }
  };

export const baseTypedDataReducer = <ItemType extends BaseItem, ExtendedState extends GenericState<ItemType>>(
  section: string,
) => {
  const ACTIONS = createActions(section);
  const actionCreators = generateActionCreators<ItemType>(ACTIONS);

  const initialState: ExtendedState = {
    isInited: false,
    isLoading: false,
    items: [] as ItemType[],
    total: 0,
    filters: {},
    sorting: {},
    offset: 0,
  } as ExtendedState;

  return {
    reducer: baseReducer<ItemType, ExtendedState>(ACTIONS),
    ACTIONS,
    AC: actionCreators,
    initialState,
    thunks: {
      onSort: onSortThunk(actionCreators),
      onFilter: onFilterThunk(actionCreators),
    },
  };
};

export type BaseTypedDataReducerInitialState<ItemType extends BaseItem> = GenericState<ItemType>;
