export const necsActions = {
  setNecsAccount: '/nibchain/necs-certificates/SET_NECS_ACCOUNT',
  setGoAccounts: '/nibchain/necs-certificates/SET_GO_ACCOUNTS',
  setGoCertificatesOnExchange: '/nibchain/necs-certificates/SET_GO_CERTIFICATES_ON_EXCHANGE',
  setTotalGoCertificates: '/nibchain/necs-certificates/SET_TOTAL_GO_CERTIFICATES',
  setNecsAccountConnectionResult: '/nibchain/necs-certificates/SET_NECS_ACCOUNT_CONNECTION_RESULT',
  setGoCertificatesPdNames: '/nibchain/necs-certificates/SET_GO_CERTIFICATES_PD_NAME',
};

export const setNecsAccount = (account) => (dispatch) => {
  dispatch({
    type: necsActions.setNecsAccount,
    payload: account,
  });
};

export const removeNecsAccount = () => (dispatch) => {
  dispatch({
    type: necsActions.setNecsAccount,
    payload: null,
  });
};

export const setGoAccounts = (accounts) => (dispatch) => {
  dispatch({
    type: necsActions.setGoAccounts,
    payload: accounts,
  });
};

export const setGoCertificatesOnExchange = (goCertificatesOnExchange) => (dispatch) => {
  dispatch({
    type: necsActions.setGoCertificatesOnExchange,
    payload: goCertificatesOnExchange,
  });
};

export const setTotalGoCertificates = (totalGoCertificates) => (dispatch) => {
  dispatch({
    type: necsActions.setTotalGoCertificates,
    payload: totalGoCertificates,
  });
};

export const setNecsAccountConnectionResult = (data) => (dispatch) => {
  dispatch({
    type: necsActions.setNecsAccountConnectionResult,
    payload: data,
  });
};

export const setGoCertificatesPdNames = (data) => (dispatch) => {
  dispatch({
    type: necsActions.setGoCertificatesPdNames,
    payload: data,
  });
};
