import _ from 'lodash';
const LOCALE = 'ru-RU';
const DATETIME_OPTS = { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };

const createActions = (section) => {
  return {
    TOGGLE_INIT: `/nibchain/${section}/TOGGLE_INIT`,
    TOGGLE_LOADING: `/nibchain/${section}/TOGGLE_LOADING`,
    SET_ITEMS: `/nibchain/${section}/SET_ITEMS`,
    SET_TOTAL: `/nibchain/${section}/SET_TOTAL`,
    REMOVE_ITEM: `/nibchain/${section}/REMOVE_ITEM`,
    ADD_ITEM: `/nibchain/${section}/ADD_ITEM`,
    EDIT_ITEM: `/nibchain/${section}/EDIT_ITEM`,
    CLEAR_ITEMS_AND_TOTAL: `/nibchain/${section}/CLEAR_ITEMS_AND_TOTAL`,
    CLEAR_ITEMS: `/nibchain/${section}/CLEAR_ITEMS`,
    ADD_FILTER: `/nibchain/${section}/ADD_FILTER`,
    SET_SORT: `/nibchain/${section}/SET_SORT`,
    REMOVE_FILTER: `/nibchain/${section}/REMOVE_FILTER`,
    CLEAR_FILTERS: `/nibchain/${section}/CLEAR_FILTERS`,
    SET_OFFSET: `/nibchain/${section}/SET_OFFSET`,
  };
};

const toBackEndDateTime = (date) =>
  new Intl.DateTimeFormat(LOCALE, DATETIME_OPTS).format(new Date(date)).replace(',', '');

const reducer = (ACTIONS) => (state, action) => {
  switch (action.type) {
    case ACTIONS.TOGGLE_INIT:
      return {
        ...state,
        isInited: action.payload,
      };
    case ACTIONS.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ACTIONS.SET_ITEMS:
      return action.payload.length
        ? {
            ...state,
            items: action.payload,
          }
        : state;
    case ACTIONS.SET_TOTAL:
      return {
        ...state,
        total: action.payload,
      };
    case ACTIONS.REMOVE_ITEM:
      return {
        ...state,
        items: [...state.items.filter((item) => item.id !== action.payload)],
        total: state.total - 1,
      };
    case ACTIONS.ADD_ITEM:
      const newItems = [...state.items];
      newItems.push(action.payload);
      return {
        ...state,
        items: newItems,
        total: state.total + 1,
      };
    case ACTIONS.EDIT_ITEM:
      const items = [
        ...state.items.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              ...action.payload,
            };
          }
          return item;
        }),
      ];

      return {
        ...state,
        items,
      };
    case ACTIONS.CLEAR_ITEMS:
      return {
        ...state,
        items: [],
      };
    case ACTIONS.CLEAR_ITEMS_AND_TOTAL:
      return {
        ...state,
        items: [],
        total: 0,
        offset: 0,
        filters: {},
        sorting: {},
      };
    case ACTIONS.ADD_FILTER:
      return {
        ...state,
        filters: {
          ...state?.filters,
          [action.payload.filter]: action.payload.value,
        },
        offset: 0,
        items: [],
        total: 0,
      };
    case ACTIONS.SET_SORT:
      return {
        ...state,
        sorting: {
          [action.payload.sort]: action.payload.value,
        },
        offset: 0,
        items: [],
        total: 0,
      };
    case ACTIONS.REMOVE_FILTER:
      return {
        ...state,
        items: [],
        total: 0,
        offset: 0,
        filters: _.omit(state?.filters, [action.payload]),
      };
    case ACTIONS.CLEAR_FILTERS:
      return {
        ...state,
        offset: 0,
        items: [],
        total: 0,
        filters: _.pick(state?.filters, 'search'),
      };
    case ACTIONS.SET_OFFSET:
      return {
        ...state,
        offset: action.payload,
      };
    default:
      return state;
  }
};

const generateAC = (ACTIONS) => {
  return {
    toggleInit: (status) => ({ type: ACTIONS.TOGGLE_INIT, payload: status }),
    toggleLoading: (status) => ({ type: ACTIONS.TOGGLE_LOADING, payload: status }),
    setItems: (items) => ({ type: ACTIONS.SET_ITEMS, payload: items }),
    setTotal: (total) => ({ type: ACTIONS.SET_TOTAL, payload: total }),
    removeItem: (id) => ({ type: ACTIONS.REMOVE_ITEM, payload: id }),
    addItem: (item) => ({ type: ACTIONS.ADD_ITEM, payload: item }),
    editItem: (item) => ({ type: ACTIONS.EDIT_ITEM, payload: item }),
    clearItems: () => ({ type: ACTIONS.CLEAR_ITEMS }),
    clearItemsAndTotal: () => ({ type: ACTIONS.CLEAR_ITEMS_AND_TOTAL }),
    addFilter: (filter, value) => ({ type: ACTIONS.ADD_FILTER, payload: { filter, value } }),
    setSort: (sort, value) => ({ type: ACTIONS.SET_SORT, payload: { sort, value } }),
    removeFilter: (filter) => ({ type: ACTIONS.REMOVE_FILTER, payload: filter }),
    clearFilters: () => ({ type: ACTIONS.CLEAR_FILTERS }),
    setOffset: (payload) => ({ type: ACTIONS.SET_OFFSET, payload }),
  };
};

const formatDataHelper = (data, opts) => {
  let localData = { ...data };
  if (opts.hasOwnProperty('getID')) {
    for (const prop of opts.getID) {
      localData[prop] = localData[prop].id ? localData[prop].id : localData[prop];
    }
  }

  if (opts.hasOwnProperty('getBackEndDate')) {
    for (const prop of opts.getBackEndDate) {
      if (localData[`${prop}.date`] instanceof Date) {
        localData[prop] = new Intl.DateTimeFormat(LOCALE, {}).format(localData[`${prop}.date`]);
      } else if (localData[`${prop}.date`]) {
        localData[prop] = new Intl.DateTimeFormat(LOCALE, {}).format(new Date(localData[`${prop}.date`]));
      } else if (localData[prop] && localData[prop].date) {
        localData[prop] = new Intl.DateTimeFormat(LOCALE, {}).format(new Date(localData[prop].date));
      } else if (typeof localData[prop] === 'string' && localData[prop].length === 10) {
        localData[prop] = new Intl.DateTimeFormat(LOCALE, {}).format(
          new Date(localData[prop].split('.').reverse().join('-')),
        );
      } else if (localData[prop]) {
        localData[prop] = new Intl.DateTimeFormat(LOCALE, {}).format(new Date(localData[prop]));
      }
    }
  }

  if (opts.hasOwnProperty('getBackEndDateTime')) {
    for (const prop of opts.getBackEndDateTime) {
      if (localData[prop]) {
        localData[prop] = toBackEndDateTime(localData[prop].date ? localData[prop].date : localData[prop]);
      }
    }
  }

  if (opts.hasOwnProperty('toMulti')) {
    for (const prop of opts.toMulti) {
      if (localData[prop]) {
        localData[`${prop}[]`] = localData[prop];
        delete localData[prop];
      } else {
        continue;
      }
    }
  }

  if (opts.hasOwnProperty('toJSON')) {
    for (const prop of opts.toJSON) {
      localData[prop] = localData[prop] ? JSON.stringify(localData[prop]) : null;
    }
  }

  if (opts.hasOwnProperty('remove')) {
    for (const prop of opts.remove) {
      if (localData.hasOwnProperty(prop)) {
        delete localData[prop];
      }
    }
  }

  if (opts.hasOwnProperty('removeIfEmpty')) {
    for (const prop of opts.removeIfEmpty) {
      if (localData.hasOwnProperty(prop) && (!localData[prop] || localData === null)) {
        delete localData[prop];
      }
    }
  }

  return localData;
};

const initialState = {
  isInited: false,
  isLoading: false,
  items: [],
  total: 0,
  filters: {},
  sorting: {},
};

/* tunks */
const onFilterTunk = (AC) => (filter, value) => (dispatch) => {
  if (filter === 'reset') return dispatch(AC.clearFilters());
  if (value !== null && value !== undefined) return dispatch(AC.addFilter(filter, value));
  dispatch(AC.removeFilter(filter));
};

const onSortTunk = (AC) => (sort, value) => (dispatch) => {
  if (value !== null && value !== undefined) return dispatch(AC.setSort(sort, value));
};

const baseDataReducer = (section) => {
  const ACTIONS = createActions(section);
  const actionCreators = generateAC(ACTIONS);

  const withLoadingStatusUpdate = (func) => async (dispatch, getState) => {
    dispatch(actionCreators.toggleLoading(true));
    const res = await func(dispatch, getState);
    dispatch(actionCreators.toggleLoading(false));
    return res;
  };

  return {
    reducer: reducer(ACTIONS),
    ACTIONS,
    AC: actionCreators,
    iniState: initialState,
    helpers: {
      formatData: formatDataHelper,
    },
    thunks: {
      onFilter: onFilterTunk(actionCreators),
      onSort: onSortTunk(actionCreators),
    },
    withLoadingStatusUpdate,
  };
};

export default baseDataReducer;
