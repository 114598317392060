/* React */
import React from 'react';
import ReactDOM from 'react-dom';

/* Redux */
import { Provider } from 'react-redux';
import store from './redux/store/store.ts';

/* Router */
import { BrowserRouter } from 'react-router-dom';

/* Components */
import App from './App';

import reportWebVitals from './reportWebVitals';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 999999999999 }}
      />
      <App />
    </Provider>
  </BrowserRouter>,

  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
