import { baseTypedDataReducer, BaseTypedDataReducerInitialState } from '../base-typed-reducer';
import { NecsProductionDevice } from './necs-production-devices.types';
import { createSlice } from '@reduxjs/toolkit';

export interface NecsProductionDevicesInitialState extends BaseTypedDataReducerInitialState<NecsProductionDevice> {
  perPage: number;
  editingDevice: NecsProductionDevice | null;
}

const { initialState, AC, reducer, thunks } = baseTypedDataReducer<
  NecsProductionDevice,
  NecsProductionDevicesInitialState
>('necs-production-devices');

export const necsProductionDevicesInitialState: NecsProductionDevicesInitialState = {
  ...initialState,
  perPage: 25,
  editingDevice: null,
  filters: {
    hasCertificates: 1,
  },
};

const necsProductionDevices = createSlice({
  name: '/nibchain/necs-production-devices',
  initialState: necsProductionDevicesInitialState,
  reducers: {
    setEditingDevice: (state, action) => {
      state.editingDevice = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state, action) => {
      return reducer(state as NecsProductionDevicesInitialState, action);
    });
  },
});

export const NecsProductionDevicesAC = AC;
export const onNecsProductionDevicesSort = thunks.onSort;
export const onNecsProductionDevicesFilter = thunks.onFilter;
export const clearNecsProductionDevicesFilters = AC.clearFilters;
export const setNecsProductionDevicesOffset = AC.setOffset;

export const { setEditingDevice: setEditingNecsProductionDevice } = necsProductionDevices.actions;

export default necsProductionDevices.reducer;
