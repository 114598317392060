import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import './store-listener';

/* Reducers import */
import appReducer from '../app-reducer.js';
import sessionReducer from '../session-reducer.js';
import companiesReducer from '../companies-reducer.js';
import productsReducer from '../products-reducer.js';
import errorReducer from '../error-reducer.js';
import ordersReducer from '../orders-reducer.js';
import usersReducer from '../users-reducer';
import landingReducer from '../landing-reducer';
import documentsReducer from '../documents-reducer';
import vaultReducer from '../vault-reducer';
import imageBankReducer from '../image-bank-reducer';
import companyTypesReducer from '../company-types-reducer.js';
import marketplaceCategoriesReducer from '../marketplace-categories-reducer';
import marketplaceIconsReducer from '../marketplace-icons-reducer';
import marketplaceVariantsReducer from '../marketplace-variants-reducer';
import marketplaceInventoryReducer from '../marketplace-inventory-reducer';
import marketplaceOrdersOldReducer from '../marketplace-orders-old-reducer';
import marketplaceOrdersReducer from '../marketplace-orders-reducer';
import companyRelationsReducer from '../company-relations-reducer';
import marketplaceUsersReducer from '../marketplace-users-reducer';
import productTypesReducer from '../product-types-reducer';
import productWrappersReducer from '../product-wrappers-reducer';
import productWrapperTypesReducer from '../product-wrapper-types-reducer';
import documentsListReducer from '../documents-list-reducer';
import reviewProductsReducer from '../review-products-reducer';
import reviewCompaniesReducer from '../review-companies-reducer';
import invitationsReducer from '../invitations-reducer';
import marketplaceHelpCategoriesReducer from '../marketplace-help-categories-reducer';
import marketplaceHelpQuestionsReducer from '../marketplace-help-questions-reducer';
import giftCardsReducer from '../gift-cards-reducer';
import routerReducer from '../router-reducer';
import { listenerMiddleware } from './store-listener';
import certificatesReducer from '../certificates/certificates-reducer';
import necsReducer from '../necs-certificates/necs-reducer';
import necsTransactionsReducer from '../necs-transacations/necs-transactions.slice';
import necsProductionDevices from '../necs-production-devices/necs-production-devices.slice';

let reducers = combineReducers({
  app: appReducer,
  session: sessionReducer,
  errors: errorReducer,
  companies: companiesReducer,
  companyTypes: companyTypesReducer,
  products: productsReducer,
  productWrappers: productWrappersReducer,
  productWrapperTypes: productWrapperTypesReducer,
  orders: ordersReducer,
  users: usersReducer,
  landing: landingReducer,
  documents: documentsReducer,
  vault: vaultReducer,
  images: imageBankReducer,
  giftCards: giftCardsReducer,
  marketplaceIcons: marketplaceIconsReducer,
  marketplaceHelpCategories: marketplaceHelpCategoriesReducer,
  marketplaceHelpQuestions: marketplaceHelpQuestionsReducer,
  marketplaceCategories: marketplaceCategoriesReducer,
  marketplaceInventory: marketplaceInventoryReducer,
  marketplaceOrdersOld: marketplaceOrdersOldReducer,
  marketplaceOrders: marketplaceOrdersReducer,
  marketplaceVariants: marketplaceVariantsReducer,
  companyRelations: companyRelationsReducer,
  marketplaceUsers: marketplaceUsersReducer,
  productTypes: productTypesReducer,
  documentsList: documentsListReducer,
  reviewProduct: reviewProductsReducer,
  reviewCompany: reviewCompaniesReducer,
  invitations: invitationsReducer,
  router: routerReducer,
  certificates: certificatesReducer,
  necs: necsReducer,
  necsTransactions: necsTransactionsReducer,
  necsProductionDevices: necsProductionDevices,
});

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  reducers,
  // @ts-ignore
  composeEnhancers(applyMiddleware(thunkMiddleware, listenerMiddleware.middleware)),
);

// @ts-ignore
window.store = store;

export default store;
